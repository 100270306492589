import React from "react";
import "./Header.css";
import { Link } from "react-router-dom";
import logo from "../../images/logo.png";
import { NavLink } from "react-router-dom";

export const Header = () => {
  return (
    <nav className="header navbar navbar-expand-lg ">
      <img src={logo} className="logo" alt="logo" />
      <button
        className="navbar-toggler border-0 shadow-none"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
     
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <ul
        className="headermenu collapse navbar-collapse"
        id="navbarSupportedContent"
      >
        <li className="nav-item">
          <NavLink to="/" className=" nav-link">
            Home
          </NavLink>
        </li>
        <li className="nav-item">
         
            <Link to="/about" className="routinglink">
              {" "}
              <a href="#AboutSession" className="nav-link">
              About
              </a>
            </Link>
         
        </li>
        <li className="dropdown nav-item">
          <Link to="/services" className="routinglink">
            Service
          </Link>
          <span
            className="dropdown-toggle nav-link"
            id="navbarDropdown"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          ></span>
          <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
            <li>
              <Link to="/servicedetail" className="dropdown-item routinglink">
                ServiceDetail
              </Link>
            </li>
          </ul>
        </li>
        <li className="dropdown nav-item">
          <Link to="/product" className="routinglink">
            {" "}
            Product
          </Link>
          <span
            className="dropdown-toggle nav-link"
            id="navbarDropdown"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          ></span>
          <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
            <li>
              <Link to="/productdetail" className="dropdown-item routinglink">
                Productdetail
              </Link>
            </li>
          </ul>
        </li>
        <li className="nav-item">
          <a href="#career-section" className="nav-link">
            <Link to="/career" className="routinglink">
              Career
            </Link>
          </a>
        </li>
        <li className="nav-item">
          <a href="#Contactsession" className="nav-link">
            <Link to="/contact" className="routinglink">
              {" "}
              Contact
            </Link>
          </a>
        </li>
      </ul>
    </nav>
  );
};
